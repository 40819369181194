import React, { useEffect, useMemo, useState } from "react";
import { Grid, Tabs, Tab, Menu, MenuItem, makeStyles, ThemeProvider } from "@material-ui/core";

import IndexedSection from "../../general/IndexedSection";
import MatDialog from "../../general/MatDialog";
import DialogFormService from "./DialogFormService";
import ServiceCard from "./ServiceCard";
import { AlertConfirm } from "./AlertConfirm";
import { ServicesService } from "../../../core/services/services.service";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TypeForm } from "../../../types/global";
import axios from "axios";
import usePlanState from "../../../store/planState";
import useGlobalState from "../../../store/globalState";
import { AlertOld } from "../../general/AlertOld";
import { themeV2 } from "../../../styles/common";
import { Provider } from "../../../types/companies-service";

interface ProviderServicesProps {
  userType: "proveedor" | "cliente";
  collaborator: Provider;
  open: boolean;
  onCancel: () => void;
}

const ServicesDialog = ({ open, onCancel, collaborator, userType }: ProviderServicesProps) => {
  const classes = useStyles();
  const canHandleServices = usePlanState((s) => s.getCanHandleServices);
  const statePlan = usePlanState((s) => s.plan);
  const [tabSelected, setTabSelected] = useState(0);
  const [openDialogNewService, setOpenDialogNewService] = useState(false);
  const [typeFormService, setTypeFormService] = useState<TypeForm>("create");
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertStatusError, setAlertStatusError] = useState({
    show: false,
    message: "",
  });
  const [alertDeleteError, setAlertDeleteError] = useState(false);
  const [anchorItemSelected, setAnchorItemSelected] = useState<HTMLElement | null>(null);
  const [serviceSelected, setServiceSelected] = useState<Service | null>(null);
  const [loadServices, setLoadServices] = useState(false);

  const [services, setServices] = useState<Service[]>([]);

  const currentServices = useMemo(() => filterCurrentServices(services), [services]);
  const pastServices = useMemo(() => filterPastServices(services), [services]);

  const { mainCompany, setAlertInfo } = useGlobalState();

  useEffect(() => {
    getServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("canHandleServices",canHandleServices(userType,statePlan))
  console.log("userType",userType)
console.log("#statePlan",statePlan)
  const getServices = async () => {
    try {
      setLoadServices(true);
      const servicesResponse: any = await ServicesService.getInstance().getCollaboratorServices(
        userType === "proveedor" ? "providers" : "clients",
        mainCompany?.id,
        collaborator?.id
      );

      const pendingServicesResponse = await ServicesService.getInstance().getPendingServices(
        userType === "proveedor" ? "providers" : "clients",
        mainCompany?.id,
        collaborator?.id
      );
      if (servicesResponse?.codigo === 0 && pendingServicesResponse?.ok) {
        const auxServices: Service[] = servicesResponse.respuesta?.map(({ authorization, ...service }) => {
          return {
            ...service,
            accepted: authorization.approved,
            status: authorization.status,
            approved: authorization.approved,
            invitationData: null,
          };
        });

        const auxPending: Service[] = pendingServicesResponse.data.map<Service>((service) => ({
          ...service,
          invitationData: {
            guestId: service.guestId,
            invitatedId: service.hostId,
            invitationId: service.invitationId,
          },
          name: service.serviceName,
          provider: service.hostId,
          companyId: service.guestId,
        }));
        setServices([...(auxServices || []), ...auxPending]);
      }
    } finally {
      setLoadServices(false);
    }
  };
  const onSubmit = () => {
    setOpenDialogNewService(false);
    getServices();
  };

  const handleAddNewService = () => {
    setServiceSelected(null);
    setOpenDialogNewService(true);
    setTypeFormService("create");
  };

  const deleteService = (data: Service | any) => {
    let serviceId = data.id;
    ServicesService.getInstance()
      .deleteService(serviceId)
      .then(() => {
        setAlertStatus(true);
        getServices();
      })
      .catch((error) => {
        setAlertStatusError({
          show: true,
          message: error.response.data.message ?? "No se pudo borrar el servicio",
        });
      });
  };

  const handleClickItemMenu = (value: "update" | "delete") => {
    switch (value) {
      case "delete":
        setAlertDeleteError(true);
        break;
      case "update":
        setTypeFormService("update");
        setOpenDialogNewService(true);
        setAnchorItemSelected(null);
        break;
      default:
        break;
    }
  };

  const getServiceStatus = (service: Service): "accepted" | "rejected" | "pending" | "waiting" => {
    if (service.approved === false) {
      return "waiting";
    }
    if (service.accepted) {
      return "accepted";
    } else if (service.invitationData) {
      if (service.invitationData.guestId === mainCompany?.id) return "pending";
      else return "waiting";
    } else return "rejected";
  };

  const handleAnserService = async (processId: string, answer: boolean) => {
    try {
      if (!processId) throw new Error("Hay un problema con los datos de la invitación");
      await ServicesService.getInstance().answertServiceInvitation(processId, answer);
      await getServices();
    } catch (error) {
      let message = "Hubo un problema al responder la invitación";
      if (axios.isAxiosError(error)) {
        message = error.message === "Network Error" ? "Error al conectar con el servidor" : error.message;
      }
      setAlertInfo({
        message,
        type: "error",
      });
    }
  };

  return (
    <>
      <MatDialog
        height="75%"
        open={open}
        action={canHandleServices(userType,statePlan) ? handleAddNewService : null}
        onClose={onCancel}
        actionText="Agregar Servicio"
        title={`Servicios del ${userType}`}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs
              value={tabSelected}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => {
                setTabSelected(newValue);
              }}
              aria-label="disabled tabs example"
            >
              <Tab className={classes.serviceTab} label="SERVICIOS ACTUALES" />
              <Tab className={classes.serviceTab} label="SERVICIOS ARCHIVADOS" />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {loadServices && <LinearProgress />}

            <IndexedSection value={tabSelected} index={0}>
              {currentServices?.map((service, index) => {
                const serviceStatus = getServiceStatus(service);
                return (
                  <ServiceCard
                    userType={userType}
                    serviceStatus={serviceStatus}
                    answerProcessId={service.invitationData?.invitationId}
                    initDate={service.serviceInitDate}
                    endDate={service.serviceEndDate}
                    id={service.id}
                    serviceTypeId={service.serviceTypeId}
                    key={index}
                    name={service.name}
                    showOptions={canHandleServices(userType,statePlan)}
                    serviceCompanyId={collaborator?.id}
                    onClickMore={(e) => {
                      if (canHandleServices(userType,statePlan)) {
                        setServiceSelected(service);
                        setAnchorItemSelected(e.currentTarget);
                      }
                    }}
                    onAnswerCollaboration={serviceStatus === "pending" ? handleAnserService : undefined}
                  />
                );
              })}

              <Menu
                id="simple-menu"
                anchorEl={anchorItemSelected}
                keepMounted
                open={Boolean(anchorItemSelected)}
                onClose={() => setAnchorItemSelected(null)}
              >
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleClickItemMenu(item.value);
                      setAnchorItemSelected(null);
                    }}
                    disabled={!item.active}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </IndexedSection>
            <IndexedSection value={tabSelected} index={1}>
              {pastServices &&
                pastServices.length > 0 &&
                pastServices.map((service, index) => (
                  <ServiceCard
                    userType={"proveedor"}
                    serviceStatus="accepted"
                    initDate={service.serviceEndDate}
                    endDate={service.serviceEndDate}
                    id={service.id}
                    serviceTypeId={service.serviceTypeId}
                    key={index}
                    name={service.name}
                    //folderId="60f5cdf09d434f3c25485d4f"
                    showOptions={canHandleServices("proveedor")}
                    serviceCompanyId={collaborator?.id}
                    onClickMore={(e) => {
                      if (canHandleServices("proveedor")) {
                        setServiceSelected(service);
                        setAnchorItemSelected(e.currentTarget);
                      }
                    }}
                  />
                ))}

              <Menu
                id="simple-menu"
                anchorEl={anchorItemSelected}
                keepMounted
                open={Boolean(anchorItemSelected)}
                onClose={() => setAnchorItemSelected(null)}
              >
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleClickItemMenu(item.value);
                    }}
                    disabled={!item.active}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </IndexedSection>
          </Grid>
        </Grid>
      </MatDialog>

      {openDialogNewService && (
        <ThemeProvider theme={themeV2}>
          <DialogFormService
            open={openDialogNewService}
            onCancel={() => setOpenDialogNewService(false)}
            submitActions={onSubmit}
            serviceSelected={serviceSelected}
            typeForm={typeFormService}
            collaborator={collaborator}
            collaboratoruserType={userType}
            //If a new service weill be created, initialValues will have provider info, otherwise (update), intiialValues
            //will have the info service to be updated.
            //initialValues={ typeFormService === 'create' ? provider : serviceSelected}
          />
        </ThemeProvider>
      )}

      <AlertOld
        open={alertStatus}
        title="Operación completada con exito"
        type="success"
        onConfirm={() => {
          setAlertStatus(false);
          setOpenDialogNewService(false);
        }}
      />

      <AlertOld
        open={alertStatusError.show}
        title={alertStatusError.message}
        type="error"
        onConfirm={() => {
          setAlertStatusError({ show: false, message: "" });
        }}
      />

      <AlertConfirm
        open={alertDeleteError}
        title={"¡Cuidado!"}
        textContent={`Estás a punto de eliminar el servicio ${serviceSelected?.name} ¿estás seguro?`}
        onConfirm={() => {
          setAlertDeleteError(false);
          deleteService(serviceSelected);
        }}
        onCancel={() => setAlertDeleteError(false)}
      />
    </>
  );
};

const useStyles = makeStyles({
  serviceTab: { fontSize: 18, fontWeight: 300 },
});

const menuItems: {
  value: "update" | "delete";
  label: string;
  active: boolean;
}[] = [
  {
    value: "update",
    label: "Editar",
    active: true,
  },
  {
    value: "delete",
    label: "Eliminar",
    active: true,
  },
];

export default ServicesDialog;

function filterCurrentServices(services: Service[]) {
  return services?.filter((service) => (service.serviceEndDate ? moment().isBefore(moment(service.serviceEndDate)) : true));
}

function filterPastServices(services: Service[]) {
  return services?.filter((service) => moment().isAfter(moment(service.serviceEndDate)));
}
