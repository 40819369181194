import { Backdrop, CircularProgress, Grid, Menu, MenuItem } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import FolderIcon from "@material-ui/icons/Folder";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ProviderUserIcon from "../components/icons/ProviderUserIcon";
import Banner from "../components/dashboard-home/pyme/Banner";
import CompanyLogo from "../components/dashboard-home/pyme/CompanyLogo";
import MenuCard from "../components/dashboard-home/pyme/MenuCard";
import usePlanState from "../store/planState";
import useGlobalState from "../store/globalState";
import useDashboardUsersState from "../store/dashboardUsersState";
import StatsIcon from "../components/icons/icons-v2/StatsIcon";
import useStagesState from "../store/stagesState";
import { capitalizeFirstLetter } from "../core/utils/utils";
import SatIcon from "../components/icons/icons-v2/SatIcon";
import Rfc69Modal from "../components/modals/Rfc69Modal";
import StatusSat69Modal from "../components/modals/StatusSat69Modal";
import StatusSat69FullModal from "../components/modals/StatusSat69FullModal";
import AlertRepse from "../components/UI/AlertRepseUpdate";
import MaintenanceModal from "../components/UI/MaintenanceModal";
import { CompaniesService } from "../core/services/companies.service";
import { Collaborator } from "../types/companies-service";
//import GeneratorFormNewUserData from "../components/generators-form-initial/new-user-form/GeneratorFormNewUserData";

const DashboardHomePage = () => {
  const { isLoadingCollaborator, handleOpenUserDialog } = useDashboardUsersState();
  const { mainCompany, setMainCompany, userCompanies, getMainCompany, additionalProducts } = useGlobalState();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const history = useHistory();
  const { getCompanyStages, companyStages } = useStagesState();
  const [showSat69Modal, setShowSat69Modal] = useState(false);
  const [showSat69ModalResult, setShowSat69ModalResult] = useState(false);
  const [showSat69ModalResultFull, setShowSat69ModalResultFull] = useState(false);

  const [showAlertContinue, setShowAlertContinue] = useState(false);
  const [showAlertMaintenanceContinue, setShowMaintenanceAlertContinue] = useState(false);
  const [showAlertREPSEIdeal, setShowAlertREPSEIdeal] = useState(false);

  const setHasReadUpdateRepseAlert = useGlobalState((s) => s.setHasReadUpdateRepseAlert);
  const hasReadUpdateRepseAlert = useGlobalState((s) => s.hasReadUpdateRepseAlert);

  const setHasReadUpdatMaintenanceAlert = useGlobalState((s) => s.setHasReadUpdatMaintenanceAlert);
  const hasReadMaintenanceAlert  = useGlobalState((s) => s.hasReadMaintenanceAlert);

  const [rfcResult, setRfcResult] = useState<any>({ rfc: null, status: false, data: null });

  const { canCreateClients, canCreateProviders, canShowHomeStages, invitedAs, invited, plan, canAccessRiesgos } = usePlanState();
  const [providers, setProviders] = useState<any>();

  useEffect(() => {
    const getProviders = async () => {
      try {
        await CompaniesService.getInstance().getAllCompanyProviders(mainCompany?.id).then((data) => {
          const providersWithGeneradores = data.respuesta.filter(provider => provider?.generadores === false);
          setProviders(providersWithGeneradores);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getProviders();
  },[]);


  const idList = [
    "61a9233ea422c73703910110", "61a92403a422c73703910111", "61a9233ea422c73703910110",
    "61a9233ea422c73703910110", "61a9233ea422c73703910110", "61a91ce1a422c7370391010b",
    "61a990a0a422c73703910125", "61a94957a422c7370391011d", "663d48209b183b6e4432ecb3",
    "61a995ffa422c73703910127", "61a91ce1a422c7370391010b", "61a91ce1a422c7370391010b",
    "61a91dfca422c7370391010c", "61a9233ea422c73703910110", "61a92403a422c73703910111",
    "61a95d09a422c73703910123", "65a9e8035f872775a905ce5f", "61a93927a422c73703910112",
    "61a93a6ba422c73703910114", "638fb3ffef0a90216668223a", "61a93fd9a422c73703910118",
    "61a94087a422c73703910119", "61a91fb0a422c7370391010d", "61fc1869a89a574343399936",
    "61a9418fa422c7370391011a", "61a94271a422c7370391011b", "61a94792a422c7370391011c",
    "61a91fb0a422c7370391010d", "61a94087a422c73703910119", "61dd9ca5e634a71554ba24cd",
    "61ddbacae634a71554ba24ce", "61a94a66a422c7370391011f", "61a9596da422c73703910122",
    "61aa31bfa422c73703910129", "61aa38b6a422c7370391012a", "61aa3c16a422c7370391012b",
    "61aa3f3ea422c7370391012c", "61aa4be4a422c7370391012d", "61aa5796a422c7370391012e",
    "61aa599ea422c7370391012f", "61aa5b19a422c73703910130", "61aa5cc3a422c73703910131",
    "61aa5e65a422c73703910132", "61a91ce1a422c7370391010b", "61a91fb0a422c7370391010d",
    "61a94a51a422c7370391011e", "61a94957a422c7370391011d", "61a93f3da422c73703910116",
    "61a94dd6a422c73703910121", "61a95d09a422c73703910123", "61a92403a422c73703910111",
    "61a98a86a422c73703910124", "61a9941ba422c73703910126", "61a995ffa422c73703910127",
    "61a91dfca422c7370391010c", "61a9233ea422c73703910110", "6324c2f357414178f982eb0b",
    "6324c93357414178f982eb0c", "63446d3551cb5d33da55f081", "61e1d41be634a71554ba24d0",
    "63e42022be1ffc212c47bda7", "65a9e8035f872775a905ce5f", "663d48209b183b6e4432ecb3",
    "61a9233ea422c73703910110", "61a9233ea422c73703910110", "61a98a86a422c73703910124",
    "61a9941ba422c73703910126", "61a91fb0a422c7370391010d", "61a920bea422c7370391010e",
    "61f2dea6a89a574343399935", "61e1d41be634a71554ba24d0", "61a91fb0a422c7370391010d",
    "61a93fd9a422c73703910118", "61a94087a422c73703910119", "61fc1869a89a574343399936",
    "61a94a66a422c7370391011f", "61a9596da422c73703910122", "61aa38b6a422c7370391012a",
    "61aa3c16a422c7370391012b", "61aa3f3ea422c7370391012c", "61aa4be4a422c7370391012d",
    "61aa5796a422c7370391012e", "61aa599ea422c7370391012f", "61aa5b19a422c73703910130",
    "61aa5cc3a422c73703910131", "61aa5e65a422c73703910132", "628d2d8b8e7ada5bef43a3eb",
    "629667fabf30750bf3ae1b23", "63f6a5dfbe1ffc212c47bdc6", "6481418b37fe97499799466c"
];

  const handleNavigateProviders = () => {
    history.push("/dashboard/home/providers");
  };
  const handleNavigateClients = () => {
    history.push("/dashboard/home/clients");
  };

  const handleCloseRepseAlert = () => {
    setShowAlertContinue(false);
    setHasReadUpdateRepseAlert(true);
  };


  const handleCloseMaintenanceAlert = () => {
    setShowMaintenanceAlertContinue(false);
    setHasReadUpdatMaintenanceAlert(true);
  };

  // const handleNavigateExpedient = () => {
  //   if (!mainCompany?.id) return;
  //   sessionStorage.setItem("companyId", mainCompany?.id);
  //   history.push("/dashboard/expediente");
  // };
  const handleNavigateStage = (stageId: string) => {
    if (!mainCompany?.id) return;
    sessionStorage.setItem("companyId", mainCompany?.id);
    history.push(`/dashboard/home/stage/${stageId}`);
  };

  const handleClickCompany = (comp) => {
    sessionStorage.setItem("companyId", comp.id);
    setMainCompany(comp);
    setAnchorEl(undefined);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(undefined);
  };

  const handleEditProfile = () => history.push("/dashboard/management/edit-profile");

  const handleRfcQuery = (rfc: string, data: any) => {
    setShowSat69Modal(false);
    if (data) {
      setRfcResult({ rfc, data });
      setShowSat69ModalResultFull(true);
    } else {
      setRfcResult({ rfc, status: false });
      setShowSat69ModalResult(true);
    }
  };

  useEffect(() => {
    if (mainCompany && canShowHomeStages) {
      getCompanyStages(mainCompany.id);
      if (mainCompany.group) {
        history.push("/dashboard/group-home");
      }
    }
    //eslint-disable-next-line
  }, [mainCompany]);

  useEffect(() => {
    //getMainCompany();
    //eslint-disable-next-line
    if(invitedAs === "provider" && hasReadUpdateRepseAlert === false && invited){
      setShowAlertContinue(true); 
    }

    if(hasReadMaintenanceAlert === false){
      // setShowMaintenanceAlertContinue(true); modal para modal de mantenimiento
    }


  }, []);

  const checkCompanyId = (mainCompanyId) => {
    return idList.includes(mainCompanyId);
  };

  useEffect(() => {
    const mainCompanyId = sessionStorage.getItem("companyId");

    const doesCompanyIdExist = checkCompanyId(mainCompanyId);
      console.log("Tipo servicio", canShowHomeStages);
      console.log("tipo de invitedAs" ,invitedAs )
      console.log("invited as client" , invited )
      console.log("doesCompanyIdExist",doesCompanyIdExist)
      console.log("doesCompanyIdExist",doesCompanyIdExist)

    if (doesCompanyIdExist  && !hasReadMaintenanceAlert && invited){
      setShowMaintenanceAlertContinue(true);
    }

  }, [hasReadMaintenanceAlert, invited]);



  return (
    <>
      {/* <GeneratorFormNewUserData /> */}
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item style={{ width: 275 }}>
          <CompanyLogo
            onChangeCompany={handleClickMenu}
            companyName={mainCompany?.name?.toUpperCase() || "no asignada"}
            onEditProfile={handleEditProfile}
            profilePictureId={mainCompany?.profilePictureId}
          />
        </Grid>
        <Grid item xs>
          <Banner />
        </Grid>
      </Grid>
      <div style={{ width: "100%", display: "flex", gap: "1rem", paddingBottom: "2rem" }}>
        <Grid item xs={12} lg={6} style={{ display: "flex", flexDirection: "column", gap: "1rem", height: "max-content" }}>
          {(invitedAs === "provider" || (plan === "CORPORATIVO" && !invited)) && (
            <Grid item xs={12}>
              <MenuCard
                title="Mis Clientes"
                secondaryButtonAction={handleNavigateClients}
                /*primaryButtonAction={
                  canCreateClients
                    ? () =>
                        handleOpenUserDialog({
                          formType: "user",
                          isNewUser: false,
                        })
                    : null
                }*/
                counter={
                  (mainCompany?.clients?.length ?? 0) +
                  (mainCompany?.pendingCollaborations?.filter((value) => value.collaboratorRole === "CLIENT")?.length ?? 0)
                }
                Icon={<PersonIcon style={{ fontSize: 28 }} />}
                //primaryButtonText="Agregar cliente"
                secondaryButtonText="Consultar lista"
                gap="100px"
              />
            </Grid>
          )}

          {(invitedAs === "client" || (plan === "CORPORATIVO" && !invited)) && (
            <Grid item xs={12}>
              <MenuCard
                title="Mis Proveedores"
                primaryButtonAction={
                  canCreateProviders
                    ? () =>
                        handleOpenUserDialog({
                          formType: "provider",
                          isNewUser: false,
                        })
                    : null
                }
                secondaryButtonAction={handleNavigateProviders}
                counter={
                  (providers?.length ?? 0) +
                  (mainCompany?.pendingCollaborations?.filter((value) => value.collaboratorRole === "PROVIDER")?.length ?? 0)
                }
                Icon={<ProviderUserIcon fontSize="small" />}
                primaryButtonText="Agregar proveedor"
                secondaryButtonText="Consultar lista"
                gap="60px"
              />
            </Grid>
          )}

          {canAccessRiesgos && (
            <Grid item xs={12}>
              <MenuCard
                title="Mi tablero de riesgo"
                primaryButtonAction={() => history.push("/dashboard/riesgos")}
                secondaryButtonAction={null}
                Icon={<StatsIcon />}
                primaryButtonText="Consultar"
                secondaryButtonText="Consultar"
                gap="60px"
              />
            </Grid>
          )}

          {additionalProducts?.includes("LISTAS_NEGRAS_SAT") && (
            <Grid item xs={12}>
              <MenuCard
                title="Análisis 69 y 69B (SAT)"
                primaryButtonAction={() => history.push("/dashboard/sat69b")}
                secondaryButtonAction={() => setShowSat69Modal(true)}
                Icon={<SatIcon />}
                primaryButtonText="Ver reporte"
                secondaryButtonText="Consultar"
                gap="60px"
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} lg={6} style={{ display: "flex", flexDirection: "column", gap: "1rem", height: "max-content" }}>
          {canShowHomeStages &&
            companyStages?.map((stage) => (
              <Grid key={stage.id} item xs={12}>
                <MenuCard
                  title={capitalizeFirstLetter(stage.name)}
                  Icon={<FolderIcon />}
                  primaryButtonText="Añadir documento"
                  secondaryButtonText="Consultar"
                  secondaryButtonAction={() => handleNavigateStage(stage.stageTemplateId)}
                  primaryButtonAction={() => handleNavigateStage(stage.stageTemplateId)}
                />
              </Grid>
            ))}
        </Grid>
      </div>

      {/* OTHERS */}
      <Menu id="options-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {userCompanies?.map((company) => (
          <MenuItem key={company.id} onClick={() => handleClickCompany(company)}>
            {company?.name}
          </MenuItem>
        ))}
      </Menu>
      <Backdrop open={isLoadingCollaborator} style={{ zIndex: 1400 }}>
        <CircularProgress color="primary" />
      </Backdrop>

      {showSat69Modal && <Rfc69Modal open={true} onClose={() => setShowSat69Modal(false)} onSubmit={handleRfcQuery} />}
      {showSat69ModalResult && (
        <StatusSat69Modal open={true} onClose={() => setShowSat69ModalResult(false)} status={rfcResult.status} rfc={rfcResult.rfc} />
      )}
      {showSat69ModalResultFull && (
        <StatusSat69FullModal open={true} onClose={() => setShowSat69ModalResultFull(false)} data={rfcResult?.data} />
      )}
       {showAlertContinue && (
          <AlertRepse
            title="IMPORTANTE"
            text="NO OLVIDES..."
            text2="Renovar tu registro REPSE consulta"
            text3="Subir a la plataforma la Determinación de prima de riesgo emitida en febrero 2024."
            onCancel={() => handleCloseRepseAlert()}
            onSubmit={() => handleCloseRepseAlert()} 
          />
        )}

       {/*showAlertMaintenanceContinue && (
            <MaintenanceModal  
            title="Estimado usuario:"
            text="Queremos brindarte una mejor experiencia, por lo que Kivit entrará en mantenimiento el próximo viernes 26 de abril a partir de las 12:01 horas reanudándose el servicio el lunes 29 de abril a las 12:01 horas."
            text2="Agradecemos tu comprensión."
            text3=""
            onCancel={() => handleCloseMaintenanceAlert()}
            onSubmit={() => handleCloseMaintenanceAlert()} 
          />
        )*/}
         
       {
        showAlertMaintenanceContinue && ( 
        <MaintenanceModal  
            title="A Todos Nuestros Proveedores REPSE"
            text="Como seguimiento al cumplimiento de la información REPSE, Grupo IDEAL solicita que la documentación del ejercicio 2023 se cargue en su totalidad a más tardar el viernes 14 de junio, con el objetivo de cumplir con las leyes vigentes y evitar sanciones y/o suspensión de pagos por los servicios prestados."
            text2="Agradecemos tu comprensión."
            text3=""
            onCancel={() => handleCloseMaintenanceAlert()}
            onSubmit={() => handleCloseMaintenanceAlert()} 
          />
       )}
    </>
  );
};

export default DashboardHomePage;