/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  FormControlLabel,
  FormGroup,
  Radio,
  Icon,
  CircularProgress,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "../../../inputs/inputs-v2/RenderTextFieldV2";
import { CompaniesService } from "../../../../core/services/companies.service";
import InfiniteScroll from "react-infinite-scroll-component";
import { FormEntityType } from "../../../../types/global";
import useDashboardUsersState from "../../../../store/dashboardUsersState";
import useGlobalState from "../../../../store/globalState";

const RadioItem = ({ item, onChange, checked }: any) => {
  return (
    <FormControlLabel
      style={{ textTransform: "capitalize" }}
      control={<Radio checked={checked} onChange={onChange} value={item.id} name="company" />}
      label={item.name}
    />
  );
};

type Props = {
  onCancel: (...args: any) => any;
  formType: FormEntityType;
  onSubmit: (selectedCompanyId: string, type: FormEntityType) => any;
  onAddNewUser: (...args: any) => any;
};

let timerId;

function FormNewUser({ onCancel, formType, onSubmit, onAddNewUser }: Props) {
  const classes = useStyles();

  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState<any>(null);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const { clients, providers, activatedGenerator } = useDashboardUsersState();
  const { typeService } = useGlobalState();

  const [addedCompanies, setAddedCompanies] = useState<string[]>();

  //const [admin, setAdmin] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string | null>(null);

  const getCompanies = async () => {
    setLoading(true);
    setHasMore(true);
    const data: any = await CompaniesService.getInstance().getCompanies({ size: 100, page, search });
    //limpio companias que llegan sin nombre y status temporal //TODO: revisar
    let cleanedCompanies;
    if (typeService === "generators") {
      console.log(typeService);
      cleanedCompanies = data?.content?.filter((comp: any) => !!comp.name && comp.status !== "TEMP" && comp.generadores);
    } else {
      cleanedCompanies = data?.content?.filter((comp: any) => !!comp.name && comp.status !== "TEMP" && !comp.generadores);
    }
    console.log(cleanedCompanies);
    
    if (data.totalPages === data.currentPage) {
      setHasMore(false);
    }
    setPage(page + 1);
    if (search) {
      setFilteredCompanies(cleanedCompanies);
    } else {
      setCompanies([...companies, ...cleanedCompanies]);
    }
    setLoading(false);
  };

  const handleSubmit = () => {
    if (!selectedCompany) {
      return;
    }
    onSubmit(selectedCompany.id, formType);
  };

  const handleCreateUser = (e: any) => {
    e.preventDefault();
    onAddNewUser();
  };

  const handleSearch = async (e: any) => {
    const value: string = e.target.value.toLowerCase();
    setPage(0);
    if (value.length > 0) {
      setSearch(value);
    } else {
      setSearch(null);
      clearTimeout(timerId);
      setCompanies([]);
      setFilteredCompanies(null);
    }
  };

  useEffect(() => {
    const wait = search ? 700 : 100;
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      getCompanies();
    }, wait);
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (formType === "provider") {
      setAddedCompanies(providers.map((comp) => comp.id));
    } else if (formType === "user") {
      setAddedCompanies(clients.map((comp) => comp.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className={classes.form} onSubmit={() => {}}>
      <Grid container>
        <Grid item md={12}>
          <Grid container xs={12} justifyContent="space-between" alignItems="center">
            <Typography className={classes.title}>{`Agregar ${formType === "provider" ? "proveedor" : "cliente" } ${activatedGenerator ? 'generador' : ''}`}</Typography>
            <IconButton aria-label="close" onClick={() => onCancel()}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>

          <div className={classes.subContainer}>
            <Typography className={classes.subtitle}>{`Encuentra a tu ${
              formType === "provider" ? "proveedor" : "cliente"
            } en nuestra base de datos: escribe su nombre o su RFC en el siguiente espacio y seleccionalo en el listado del segundo recuadro.`}</Typography>

            <TextField onChange={handleSearch} placeholder="Buscar por Nombre o RFC" startIcon={<Icon>search</Icon>} style={{paddingBottom:'1rem',paddingTop:'1rem'}}/>

            <div className="my-2">
              <Typography className={classes.subtitle}>{`Elige a tu ${
                formType === "provider" ? "proveedor" : "cliente"
              } en el siguiente listado`}</Typography>
            </div>

            <FormGroup style={{ position: "relative" }}>
              {loading && (
                <div className={classes.listBackdrop}>
                  <CircularProgress color="inherit" />
                </div>
              )}

              {/* {(filteredCompanies || companies)?.map((comp: any) => {
                //limpio compania ya agregada
                if (addedCompanies?.includes(comp?.id)) return null;

                return (
                  <RadioItem
                    key={comp.id}
                    item={comp}
                    checked={selectedCompany?.id === comp.id}
                    onChange={(e: any) => setSelectedCompany(comp)}
                  />
                );
              })} */}

              <InfiniteScroll
                className={classes.companiesList}
                style={{ overflowY: loading ? "hidden" : "scroll" }}
                dataLength={(filteredCompanies || companies).length}
                next={getCompanies}
                hasMore={hasMore}
                height={180}
                loader={<div></div>}
              >
                {(filteredCompanies || companies)?.map((comp: any) => {
                  //limpio compania ya agregada
                  if (addedCompanies?.includes(comp?.id)) return null;

                  return (
                    <RadioItem
                      key={comp.id}
                      item={comp}
                      checked={selectedCompany?.id === comp.id}
                      onChange={(e: any) => setSelectedCompany(comp)}
                    />
                  );
                })}
              </InfiniteScroll>
            </FormGroup>

            <Typography className={classes.linkBtn}>
              <Link to="#" onClick={handleCreateUser}>
                {`No encontré a mi ${
                  formType === "provider" ? "proveedor" : "cliente"
                } por lo que deseo darlo de alta en su base de datos.`}
              </Link>
            </Typography>
          </div>

          <Grid container xs={12} justifyContent="center">
            <div className="text-center">
              <Typography style={{ display: "block" }} variant="caption">
                * Campos obligatorios
              </Typography>
              <Button
                onClick={handleSubmit}
                className="mt-2"
                type="button"
                variant="contained"
                color="primary"
                disabled={!selectedCompany}
              >
                AGREGAR
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    color: theme.palette.black.main,
  },
  subContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  companiesList: {
    border: "2px solid",
    borderColor: theme.palette.black.main,
    borderRadius: "8px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    height: "180px",
    overflow: "hidden",
    //overflowY: "scroll",
    position: "relative",
  },
  listBackdrop: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 112, 158, 0.6)",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: theme.zIndex.drawer,
  },
  linkBtn: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 600,
    textDecoration: "underline",
    textAlign: "center",
  },
  endDetector: {
    height: "10px",
    width: "100%",
    backgroundColor: "red",
  },
}));

export default FormNewUser;
