import React, { useEffect, useMemo, useState } from "react";
import { Button, CircularProgress, Dialog, Grid, makeStyles, ThemeProvider, Typography } from "@material-ui/core";

import CardDashboardUser from "./CardDashboardUser";
import TagTemplateFilter from "../../general/TagTemplateFilter";
import ServicesDialog from "../services/ServicesDialog";
import AddIcon from "@material-ui/icons/Add";
import { themeV2 } from "../../../styles/common";
import SearchCompany from "../SearchCompany";
import usePlanState from "../../../store/planState";
import GoBackButton from "../../general/GoBackButton";
import InfiniteScroll from "react-infinite-scroll-component";
import useGlobalState from "../../../store/globalState";
import UserShowContact from "./users-dialog-contents/UserShowContact";
import UserDeleteConfirm from "./users-dialog-contents/UserDeleteConfirm";
import UserDeletedAlert from "./users-dialog-contents/UserDeletedAlert";
import useDashboardUsersState from "../../../store/dashboardUsersState";
import { CompaniesService } from "../../../core/services/companies.service";
import { Provider } from "../../../types/companies-service";

interface UsersProps {
  users: Provider[];
  title: string;
  userType: "proveedor" | "cliente";
  filters?: UserFilter[];
  hasServices?: boolean;
  onAdd: () => void;
  onToggle?: (id: string, enabled: boolean) => void;
  onEdit?: (user: any) => void;
  loading?: boolean;
  onSearch?: (query: string) => void;
  onAnswerInvitation?: (processId: string, answer: boolean) => void;
  loadProvider?: () => Promise<void>;
  loadClients?: () => Promise<void>;
}

const DashboardUsers = ({
  users,
  userType,
  filters,
  title,
  onAdd,
  onEdit,
  onToggle,
  onSearch,
  onAnswerInvitation,
  hasServices,
  loading,
  loadProvider,
  loadClients
}: UsersProps) => {
  const classes = useStyles();

  const { deleteProviderOrClient, clients, setClients, providers, setProviders, setActivatedGenerator, setIsLoadingCollaborator } = useDashboardUsersState();

  const [showUsersType, setShowUsersType] = useState<UserFilterType>("all");
  const [openDialogProviderServices, setOpenDialogProviderServices] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState<Provider>();
  const [showedItemsCount, setShowedItemsCount] = useState(20);

  //card states
  const [currentContact, setCurrentContact] = useState<any>(null);
  const [userToDelete, setUserToDelete] = useState<Provider>(null);
  const [showDialog, setShowDialog] = useState(false);
  const [userDeleted, setUserDeleted] = useState<"success" | "error" | null>(null);

  // Generadores
  const { additionalProducts, typeService } = useGlobalState();
  const [isGenerator, setIsGenerator] = useState(false);

  useEffect(() => {
    for (const item of (additionalProducts as string[])) {
      if (item === "GENERADORES") setIsGenerator(true)
    }
  }, [additionalProducts]);

  /* const findAllProviders = async () => {
    setIsLoadingCollaborator(true);
    if (!mainCompany?.id) return;
    try {
      const data = await CompaniesService.getInstance().getCompanyProviders(mainCompany?.id);
      let providersWithGeneradores
      if (typeService === "generators") {
        providersWithGeneradores = data.providers.filter(provider => provider?.generadores === true);
      } else {
        providersWithGeneradores = data.providers.filter(provider => provider?.generadores === false);
      }

       
      //@ts-ignore
      setProviders([...providersWithGeneradores, ...data?.pendingCollaborations]);
    } finally {
      setIsLoadingCollaborator(false);
    }
  }; */

  const handleCloseDialog = () => {
    setCurrentContact(null);
    setUserToDelete(null);
    setUserDeleted(null);
    setShowDialog(false);
  };

  function onShowContact(user: DashboardUser) {
    setCurrentContact(user);
    setShowDialog(true);
  }

  const handleCardDelete = (userCompany: any) => {
    setUserToDelete(userCompany);
    setShowDialog(true);
  };

  const handleConfirmDelete = async (pendingStatus: any) => {
    console.log('handleConfirmDelete');
    const type = userType === "proveedor" ? "provider" : userType === "cliente" ? "client" : null;
    let data;
    if (pendingStatus === "REQUEST")
      data = await CompaniesService.getInstance().removePendingCollaborator(
        userToDelete?.invitationId,
        userToDelete?.id,
        mainCompany.id
      );
    else data = await deleteProviderOrClient(type, userToDelete?.id);
    handleCloseDialog();

    if (data?.codigo === 0) {
      setUserDeleted("success");
      if (loadClients) loadClients()
      if (loadProvider) loadProvider()
    } else {
      setUserDeleted("error");
    }
    setShowDialog(true);
  };

  const handleDeletedAlertSubmit = (userCompany: any) => {
    const type = userType === "proveedor" ? "provider" : userType === "cliente" ? "client" : null;
    if (type === "provider") {
      setProviders(providers.filter((provider) => provider.id !== userCompany.id));
    } else if (type === "client") {
      setClients(clients.filter((client) => client.id !== userCompany.id));
    }
    setShowDialog(false);
  };

  const mainCompany = useGlobalState((s) => s.mainCompany);
  const canCreateCollaborator = usePlanState((s) => (userType === "cliente" ? s.canCreateClients : s.canCreateProviders));

  const handleClickUser = (user: Provider) => {
    setOpenDialogProviderServices(true);
    setSelectedCollaborator(user);
  };

  const usersToShow = useMemo(() => {
    return users?.slice(0, showedItemsCount);
  }, [users, showedItemsCount]);

  const hasMoreItems = users?.length > showedItemsCount;

  const toggleGenerator = (flag: boolean) => {
    setActivatedGenerator(flag);
  }

  function showMoreItems() {
    setShowedItemsCount(showedItemsCount + 20);
  }
  console.log("page repse")
  return (
    <Grid className="my-3" container spacing={2} style={{ minHeight: "200px" }}>
      <Grid item xs className={classes.row}>
        <div style={{ marginRight: "2rem" }}>
          <GoBackButton />
        </div>
        <div className={classes.titleWrapper}>
          <Typography variant="h4">{title}</Typography>
        </div>
        {canCreateCollaborator && (
          <div>
            <Button variant="contained"
              onClick={() => { onAdd(); toggleGenerator(false) }}
              className={classes.newUserBtn} startIcon={<AddIcon />}>
              {`Agregar nuevo ${userType}`}
            </Button>
            {/* Ocultar btn - vista repse
            isGenerator && (  
              <Button variant="contained" color="primary"
                onClick={() => { onAdd(); toggleGenerator( true ) }} className={`${classes.newUserBtn} ml-3`} startIcon={<AddIcon />}>
                {`Agregar nuevo ${userType} generador`}
              </Button>
            )*/}
          </div>
        )}
      </Grid>
      {!!onSearch && (
        <Grid item style={{ height: "max-content" }}>
          <SearchCompany onSearch={onSearch} />
        </Grid>
      )}

      <Grid item container sm={12} direction="row">
        <TagTemplateFilter label="Todos" selected={showUsersType === "all"} count={users.length} onClick={() => setShowUsersType("all")} />
        {filters?.map((filter, i) => (
          <TagTemplateFilter
            key={i}
            label={filter.label}
            selected={showUsersType === filter.type}
            count={users.filter((companySelf) => companySelf?.type === filter?.type).length}
            onClick={() => setShowUsersType(filter.type)}
          />
        ))}
      </Grid>

      {loading ? (
        <div className={classes.placeholderWrapper}>
          <CircularProgress color="primary" />
        </div>
      ) : !users.length && !loading ? (
        <div className={classes.placeholderWrapper}>
          <Typography variant="subtitle2">No se encontraron {userType === "proveedor" ? "proveedores" : "clientes"}</Typography>
        </div>
      ) : (
        <>
          <InfiniteScroll
            style={{ width: "100%", overflow: "hidden" }}
            dataLength={usersToShow?.length}
            next={showMoreItems}
            hasMore={hasMoreItems}
            loader={null}
          >
            <Grid container spacing={2}>
              {usersToShow?.map((user, index) => {
                if (user.type === showUsersType || showUsersType === "all") {
                  return (
                    <Grid lg={6} xs={12} item key={user.id}>
                      <CardDashboardUser
                        repse={user.repse}
                        onShowContact={onShowContact}
                        onDelete={handleCardDelete}
                        mainCompanyName={mainCompany?.name}
                        user={user}
                        invitationId={user.invitationId}
                        pendingStatus={user.type as "REQUEST" | "INVITATION"}
                        userType={userType}
                        onToggleActivate={onToggle}
                        onEdit={onEdit}
                        onClick={() => hasServices && handleClickUser(user)}
                        onAnswerCollaboration={onAnswerInvitation}
                        blackList={
                          user.blacklist?.status
                            ? "PERTENECE"
                            : user.blacklist?.status === false
                              ? "NO PERTENECE"
                              : "BAJO REVISIÓN"
                        }
                      />
                    </Grid>
                  );
                } else return null;
              })}
            </Grid>
          </InfiniteScroll>
          {openDialogProviderServices && (
            <ThemeProvider theme={themeV2}>
              <ServicesDialog
                open={openDialogProviderServices}
                onCancel={() => setOpenDialogProviderServices(false)}
                collaborator={selectedCollaborator}
                userType={userType}
              />
            </ThemeProvider>
          )}

          {showDialog && (
            <ThemeProvider theme={themeV2}>
              <Dialog open={showDialog} fullWidth maxWidth="md">
                {currentContact && <UserShowContact contact={currentContact} onCancel={handleCloseDialog} />}
                {userToDelete && (
                  <UserDeleteConfirm
                    user={userToDelete}
                    userType={userType}
                    onCancel={handleCloseDialog}
                    onSubmit={handleConfirmDelete}
                    disabled={loading}
                  />
                )}
                {userDeleted && (
                  <UserDeletedAlert
                    success={userDeleted}
                    userType={userType}
                    onCancel={handleCloseDialog}
                    onSubmit={handleDeletedAlertSubmit}
                  />
                )}
              </Dialog>
            </ThemeProvider>
          )}
        </>
      )}
    </Grid>
  );
};
export default DashboardUsers;

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    height: "max-content",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  newUserBtn: {
    textTransform: "unset",
    minWidth: "240px",
    color: "#fff",
    backgroundColor: theme.palette.purple.main,
    "&:hover": {
      backgroundColor: theme.palette.purple.dark,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },
  },
  titleWrapper: {
    color: theme.palette.black.main,
    minWidth: "200px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
    },
  },
  placeholderWrapper: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
