import { Modal, Backdrop, Fade, makeStyles, IconButton, Typography, Box, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: 5,
    marginTop: 8
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #e2e8f0',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: '80%',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #e2e8f0',
    padding: theme.spacing(4),
    boxShadow: theme.shadows[5],
  },
  saveBtn: {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    marginTop: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

interface ComponentProps {
  open: boolean;
  handleClose: () => void;
  handleUnArchive?: () => Promise<void>; // Agrega handleUnArchive como opcional o requerido según el caso
}

const UnArchiveMessageModal = ({ open, handleClose, handleUnArchive }: ComponentProps) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="unarchived-modal-title"
      aria-describedby="unarchived-modal-description"
      /* className={classes.modal} */
      open={open}
      onClose={handleClose}
    >
      <Fade in={open}>
        <Box className={classes.style}>
          <Typography color='primary' id="unarchived-modal-title" variant="h6" component="h2">
            Desarchivar generador
          </Typography>
          <Typography id="unarchived-modal-description">
            El generador está por sacarte del archivo, ¿Está seguro de continuar?
          </Typography>
          <Box className={classes.modal}>
            <Button variant="contained" color="primary" onClick={handleUnArchive}>Si, Desarchivar</Button>
            <Button variant="outlined" color="primary" onClick={() => handleClose()}>Cancelar</Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UnArchiveMessageModal;
