import moment from "moment";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import { getCurrentUser } from "../core/helpers/user.helper";
import { AuthService } from "../core/services";
import { UserService } from "../core/services/user.service";
import { parseJwt } from "../core/utils/utils";
import useGlobalState from "./globalState";
import useNotificationState from "./notificationState";
import { CompaniesService } from "../core/services/companies.service";
import useDashboardUsersState from "./dashboardUsersState";
import { PasswordPolicyList } from "../core/models/settings.models";
import useSettingsState from "./settingsState";
const authService = new AuthService();

const oneHour = 1000 * 60 * 60;
const SESSION_DURATION = oneHour * 2;
let sessionInterval: any;

interface CurrentUser {
  isProvider: boolean;
  user_name: any;
  authorities: any;
  client_id: any;
  userId: any;
  name?: any;
}

export interface AuthPolicyData {
  id: string;
  companyId: string;
  userId: string;
  value: string;
  loginResponse: string;
  createdDate: string;
  lastModifiedDate: string;
  email: string;
  passwordPolicyList: PasswordPolicyList[];
}

interface State {
  loggedIn: boolean;
  keep_active_session: boolean;
  currentUser: CurrentUser;
  newUserInvited: boolean;
  updatedPassword: boolean;
  mustUpdateCompany: boolean;
  sessionWillBeExpired: boolean;
  clientExpireDate: Date;
  authPolicyData: AuthPolicyData;
  setAuthPolicyData: (data: AuthPolicyData) => void;
  activeSession: (active: boolean) => any;
  login: (username: string, password: string) => any;
  onLogin: (response: any) => Promise<void>;
  logout: () => any;
  refreshToken: () => any;
  refreshTokenNew: () => any;
  restorePassword: (uuid: string) => any;
  sendEmailPassword: (email: string) => any;
  signUp: (user: any) => any;
  closeAlert: () => any;
  setUpdatedPassword: (updatedPassword: boolean) => any;
  reset: () => any;
}

//STATE
export const useSessionState = create<State>((set, get) => ({
  loggedIn: !!sessionStorage.access_token, //TODO: revisar
  keep_active_session: false,
  currentUser: !!sessionStorage.access_token ? getCurrentUser(sessionStorage.userId) : null,
  newUserInvited: false,
  updatedPassword: false,
  mustUpdateCompany: false,
  sessionWillBeExpired: false,
  clientExpireDate: null,
  authPolicyData: null,
  activeSession: activeSession(set, get),
  login: login(set, get),
  onLogin: (response: any) => onLogin(response),
  logout: logout(set, get),
  refreshToken: refreshToken(set, get),
  refreshTokenNew: refreshTokenNew(set, get),
  restorePassword: restorePassword(set, get),
  sendEmailPassword: sendEmailPassword(set, get),
  setAuthPolicyData: setAuthPolicyData(set, get),
  signUp: signUp(set, get),
  closeAlert: closeAlert,
  setUpdatedPassword: (updatedPassword: boolean) => {
    set({ updatedPassword });
  },
  reset: () => {
    set({
      loggedIn: false,
      keep_active_session: false,
      currentUser: null,
      newUserInvited: false,
      updatedPassword: false,
      mustUpdateCompany: false,
      sessionWillBeExpired: false,
      clientExpireDate: null,
      authPolicyData: null
    });
  },
}));

export default useSessionState;

const wait = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds));

//FUNCTIONS
function login(set: NamedSet<State>, get: GetState<State>) {
  return async (username: string, password: string) => {
    await authService
      .ipfsToken(username, password) //on login
      .then(async (response) => {
        onLogin(response)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          if (error.response.data.error_description === "Bad credentials") {
            useGlobalState.getState().setAlertInfo({
              message: "Usuario o contraseña incorrectos.",
              type: "error",
            });
          } else if (error.response.data.error_description === "User account is locked") {
            useGlobalState.getState().setAlertInfo({
              message: "La cuenta ha sido bloqueada.",
              type: "error",
            });
          }
        } else if (error?.response?.status === 500) {
          if (error?.response?.data) {
            /** */
            const message: string = error.response.data.mensaje.split('|')[1].split('/')[0];

            useGlobalState.getState().setAlertInfo({
              message,
              type: "error",
            });
          }
        } else {
          useGlobalState.getState().setAlertInfo({
            message: "Ocurrió un error inesperado, intenta más tarde.",
            type: "error",
          });
        }
      });
  };
}

async function onLogin(response: any) {
  //-------------------------------------------------------->> DECODE TOKEN
  const { access_token, refresh_token } = response;
  const tokenPayload = parseJwt(access_token);
  const userId = tokenPayload.sub;
  //const companyId = tokenPayload.companyId[0];
  //-------------------------------------------------------->> SET ON SESSION STORAGE
  sessionStorage.setItem("access_token", access_token);
  sessionStorage.setItem("refresh_token", refresh_token);
  sessionStorage.setItem("userId", userId);
  sessionStorage.setItem("token_expire", moment().add(10, "m").format());
  //sessionStorage.setItem('companyId', companyId);

  //-------------------------------------------------------->> GET USER LOGEED INFO
  let userInfo

  await wait(500)

  userInfo = await UserService.getInstance().getUser(userId); //TODO: revisar permiso back

  for (const item of tokenPayload?.realm_access?.roles || []) {
    if (item === 'Admin') {
      userInfo.roles = [item]; // Si es Admin, asigna y detiene el bucle
      break;
    } else if (item === 'Reader') {
      userInfo.roles = [item]; // Si es Reader, asigna el valor en posición 0
    }
  }

  if (!userInfo) {
    await wait(500)
    userInfo = await UserService.getInstance().getUser(userId);
  }

  if (!userInfo) {
    await wait(500)
    userInfo = await UserService.getInstance().getUser(userId);
  }

  userInfo.name = tokenPayload.name;
  //UserService.getInstance().getUser2(userId); //temp test
  //VALIDO USER EXISTA
  if (!userInfo.id) {
    useGlobalState.getState().setAlertInfo({
      message: "Ocurrió un error inesperado, intenta más tarde.",
      type: "error",
    });
    return;
  }

  useGlobalState.getState().setUserInfo(userInfo as any);

  //-------------------------------------------------------->> GET COMPANy BY OWNER(LOGGED USER)
  const mainCompany = await useGlobalState.getState().getMainCompany();
  //VALIDO TENGA COMPAÑIAS
  if (!mainCompany) {
    useGlobalState.getState().setAlertInfo({
      message: "Ocurrió un error inesperado, intenta más tarde.",
      type: "error",
    });
    return;
  }

  //verifico si actualizo datos de la compania a la que pertenece
  const updatedCompanyData = mainCompany?.updatedData; //TODO SEVERAL: revisar caso de multiple companias
  const mustUpdateCompanyData = !updatedCompanyData;

  // Verificar si hay productos adicionales
  const additionalProducts = await useGlobalState.getState().getCompanyAdditionalProducts(userInfo.companies[0]);

  if (additionalProducts && additionalProducts.length > 0) {
    for (const product of additionalProducts) {
      if (product.type === "REPSE") useDashboardUsersState.setState({ showRepseService: true })
      if (product.type === "GENERADORES") useDashboardUsersState.setState({ showGeneratorService: true })
    }
  }

  useSessionState.setState({
    loggedIn: !!sessionStorage.access_token,
    currentUser: !!sessionStorage.access_token ? getCurrentUser(userId) : null,
    newUserInvited: mainCompany.invited,
    updatedPassword: Boolean(userInfo.updatedPassword),
    mustUpdateCompany: mustUpdateCompanyData,
    clientExpireDate: new Date(Date.now() + SESSION_DURATION),
  });

  //SESSION EXPIRE

  sessionInterval = setInterval(() => {
    const isExpired = useSessionState.getState().clientExpireDate < new Date();
    if (isExpired) {
      useSessionState.setState({ sessionWillBeExpired: true });
      setTimeout(() => {
        useSessionState.getState().logout();
        clearInterval(sessionInterval);
        alert("Su sesión ha expirado");
      }, 2000);
    }
  }, 60000);
}

function signUp(set: NamedSet<State>, get: GetState<State>) {
  return (user: any) => {
    authService
      .signUp(user)
      .then(() => {
        useGlobalState.getState().setAlertInfo({
          message: "Activación exitosa",
          type: "success",
        });
      })
      .catch((error) => {
        useGlobalState.getState().setAlertInfo({
          message: !error.response.data.message ? "Ocurrio un error al crear la cuenta" : error.response.data.message,
          type: "error",
        });
      });
  };
}

function activeSession(set: NamedSet<State>, get: GetState<State>) {
  return (keep_active_session: boolean) => {
    set({ keep_active_session });
  };
}

function logout(set: NamedSet<State>, get: GetState<State>) {
  return () => {
    //clean notification state
    useNotificationState.getState().reset();
    //clean DashboardUsers State
    useDashboardUsersState.getState().reset();
    //clean settings state
    useSettingsState.getState().reset();
    // Cerrar sesión keycloak
    authService.logout();
    //clean session storage
    sessionStorage.clear();
    //clear local storage
    localStorage.clear();
    //clean global state
    useGlobalState.getState().reset();
    // Clean session interval
    clearInterval(sessionInterval);
    //clean session state
    get().reset();
  };
}

function sendEmailPassword(set: NamedSet<State>, get: GetState<State>) {
  return (email: string) => {
    authService
      .sendEmailPassword(email)
      .then((response) => {
        useGlobalState.getState().setAlertInfo({
          message: "Email enviado correctamente. Revise su bandeja con las instrucciones para restablecer su contraseña.",
          type: "success",
        });
      })
      .catch((error) => {
        useGlobalState.getState().setAlertInfo({
          message: error.response.data.message,
          type: "error",
        });
      });
  };
}

function restorePassword(set: NamedSet<State>, get: GetState<State>) {
  return (uuid: string) => {
    authService
      .restorePassword(uuid)
      .then((response) => {
        useGlobalState.getState().setAlertInfo({
          message: "Constraseña restablecida correctamente.",
          type: "success",
        });
      })
      .catch((error) => {
        useGlobalState.getState().setAlertInfo({
          message: error.response.data,
          type: "error",
        });
      });
  };
}

function refreshToken(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    await authService
      .refreshToken(sessionStorage.getItem("refresh_token"))
      .then((response) => {
        //TODO: implement
      })
      .catch((error) => { });
  };
}

function refreshTokenNew(set: NamedSet<State>, get: GetState<State>) {
  return async () => {
    await authService
      .newRefreshToken(sessionStorage.getItem("refresh_token_ipfs"))
      .then((response) => {
        const { access_token, refresh_token } = response;
        sessionStorage.setItem("access_token_ipfs", access_token);
        sessionStorage.setItem("refresh_token_ipfs", refresh_token);
        //TODO: implement
      })
      .catch((error) => {
        //
      });
  };
}

function closeAlert() {
  useGlobalState.getState().setAlertInfo(undefined);
}

function setAuthPolicyData(set: NamedSet<State>, get: GetState<State>) {
  return (data: AuthPolicyData) => {
    sessionStorage.setItem('authPolicyData', JSON.stringify(data));
    set({ authPolicyData: data });
  }
}