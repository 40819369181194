import { authHeader, axiosBPMRequestV1, axiosCompanyRequestV1, axiosGeneradoresRequest } from "../../configs/axios.config";
import { CreateCompanyReqBody, EditCompanyInfoReqBody, EditCompanyPictureReqBody, GetClientResponse, GetProviderListResponse, GetProviderResponse, GetUserCompaniesResponse } from "../../types/companies-service";
import { GenericResponse } from "../../types/global";

export class CompaniesService {
  private static instance: CompaniesService;

  private constructor() { }

  public static getInstance(): CompaniesService {
    if (!CompaniesService.instance) {
      CompaniesService.instance = new CompaniesService();
    }
    return CompaniesService.instance;
  }

  async getActiveCompanies() {
    //user auth companies
    try {
      const { data } = await axiosCompanyRequestV1.get<GetUserCompaniesResponse>(`/companies/owner`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAdditionalInfo(idProvider: string) {
    //user auth companies
    try {
      const { data } = await axiosCompanyRequestV1.get<any>(`/additional/generadoresInfo/idProvider/${idProvider}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createCompany(body: any) {
    try {
      const { data } = await axiosCompanyRequestV1.post(`/companies`, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }




  async createCompanyInit(body: CreateCompanyReqBody, generator: boolean) {
    try {
      const path: string = `/companies/${generator ? 'generadores/' : ''}init`;
      const { data } = await axiosCompanyRequestV1.post(path, body, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCompany(companyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.get(`/companies/${companyId}/public`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCompany(body: EditCompanyInfoReqBody) {
    const { data } = await axiosCompanyRequestV1.put<GenericResponse>(`/companies`, body, { headers: authHeader() });
    return data;
  }

  async getCompanies({ page, search, size = 500 }: { page?: number; search?: string; size?: number }) {
    const params: any = { page: page || 0, size };
    if (search) {
      params["search"] = search;
    }
    try {
      const { data } = await axiosCompanyRequestV1.get("/companies", {
        params,
        headers: authHeader(),
      }); //TODO: paginado dinamico
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addCompanyAsProvider(companyId: string, providerCompanyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.patch(`/companies/${companyId}/providers/${providerCompanyId}`, null, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addCompanyAsClient(companyId: string, clientCompanyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.patch(`/companies/${companyId}/clients/${clientCompanyId}`, null, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addCollaborator(guestId: string, invitatedId: string, collaboratorType: "client" | "provider") {
    try {
      const { data } = await axiosBPMRequestV1.post(
        `/bpm/companies/coloaboration/${collaboratorType}`,
        {
          guestId,
          invitatedId,
        },
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }


  async addCollaboratorV2(clientId: string, providerId: string, generator: boolean) {
    try {
      const path: string = `/companies/${generator ? 'generadores/' : ''}collaborations/${clientId}/collaborator/${providerId}`;
      const { data } = await axiosCompanyRequestV1.get(
        path,
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async asnwerCollaboration(processId: string, answer: boolean) {
    const { data } = await axiosBPMRequestV1.patch(`/bpm/companies/colaboration/${processId}?invitationResponse=${answer}`, null, {
      headers: authHeader(),
    });
    return data;
  }

  async archiveCompany(companyId: string, archive: boolean) {
    const { data } = await axiosCompanyRequestV1.patch(`/companies/${companyId}/archived/${String(archive)}`, null, {
      headers: authHeader(),
    });
    return data;
  }

  async removeCompanyAsProvider(companyId: string, providerCompanyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.delete(`/companies/${companyId}/providers/${providerCompanyId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async removePendingCollaborator(invitationId: string, guestId: string, invitatedId: string) {
    const { data } = await axiosBPMRequestV1.delete<{ status: true }>(`/bpm/companies/request/${invitationId}`, {
      data: {
        guestId,
        invitatedId
      },
      headers: authHeader(),
    });
    return data;
  }

  async removeCompanyAsClient(companyId: string, clientCompanyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.delete(`/companies/${companyId}/clients/${clientCompanyId}`, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCompanyProviders(companyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.get<GetProviderResponse>(`companies/${companyId}/providers`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCompanyProviders(companyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.get<GetProviderListResponse>(`companies/${companyId}/providerList`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCompanyClients(companyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.get<GetClientResponse>(`companies/${companyId}/clients`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCompanyClients(companyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.get<GetProviderListResponse>(`companies/${companyId}/clientList`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editCompanyProfilePicture(companyId: string, body: EditCompanyPictureReqBody) {
    const { data } = await axiosCompanyRequestV1.post<{ status: boolean }>(`companies/${companyId}/profilepicture`, body, { headers: authHeader() });
    return data;
  }


  async getCompanyByGroup(companyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.get<GetClientResponse>(`companies/${companyId}/groups`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }


  async getAdditionalProducts(companyId: string) {
    try {
      const { data } = await axiosCompanyRequestV1.get(`additional/product/company/${companyId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }


  async getInitAdditionalGeneradoresInfo(companyId: string, idProvider: string) {
    try {
      const { data } = await axiosCompanyRequestV1.post(
        `additional/generadoresInfo/init/idCompany/${companyId}/idProvider/${idProvider}`,

        {},
        {
          headers: authHeader(),
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }


  async updateAdditionalGeneradoresInfo(body: any) {
    try {
      const { data } = await axiosCompanyRequestV1.patch(`/additional/generadoresInfo`, body, {
        headers: authHeader(),
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getServiceOffered(companyId: string) {
    try {
      const { data } = await axiosGeneradoresRequest.get(`serviceOffered/idCompany/${companyId}`, { headers: authHeader() });
      return data;
    } catch (error) {
      throw error;
    }
  }

}//end




