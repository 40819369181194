import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    InputAdornment,
    IconButton,
    TablePagination,
    Chip,
    Box,
    Popper,
    ClickAwayListener,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu";
import { Search, FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight, FilterList } from '@material-ui/icons';
import useGlobalState from "../../../../store/globalState";
import useCustomToast from "../../../../core/hooks/useCustomToast";
import { GeneradoresServices } from '../../../../core/services/generadores.service';
import useUiState from '../../../../store/uiState';
import PdfModal from '../../../UI/PdfViewer';
import ReportGenerator from '../../../dashboard/FormsGenerator/ReportGenerator';
import ArchiveMessageModal from '../../../UI/ArchiveMessageModal';
import UnArchiveMessageModal from '../../../UI/UnArchiveMessageModal';
import { set } from 'date-fns';
import useGeneratorClientState from '../../../../store/generadores/generadorClientState';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    searchContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: '8px',
        marginBottom: '8px',
        paddingLeft: '4px',
        gap: '16px'
    },
    searchInput: {
        width: '300px',
        padding: 0,
        borderBottom: '1px solid #ccc'
    },
    container: {
        backgroundColor: '#fff',
        padding: '16px'
    },
    labelContainer: {
        textAlign: 'center'
    },
    labelCol: {
        color: theme.palette.primary.main,
        display: 'block',
    },
    badgeContainer: {
        textAlign: 'center'
    },
    badgeBg: {
        width: '120px'
    },
    badgeStyles: {
        colorPrimary: theme.palette.success,
        colorSecondary: theme.palette.warning
    },
    paginatorButtons: {
        display: 'flex'
    },
    statusButton: {
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
    },
    pagado: {
        backgroundColor: 'green',
    },
    revision: {
        backgroundColor: '#ffdf00',
        color: 'black',
    },
    rechazado: {
        backgroundColor: 'red',
    },
    popper: {
        zIndex: theme.zIndex.tooltip,
        borderRadius: '10px',
        boxShadow: theme.shadows[5],
    },
    popperContent: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px', // Ensure content inside is also rounded
    },
}));

const CustomBiddingTable = ({ data }) => {
    const mainCompanyId = useGlobalState((s) => s.mainCompany.id);
    const { getGeneratorsLite, generatorsLite } = useGeneratorClientState();
    const classes = useStyles();
    const toast = useCustomToast();
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const { setShowGenericLoader } = useUiState();
    const [dataUrl, setDataUrl] = useState('');
    const [pdfView, setPdfView] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [anchorEl, setAnchorEl] = useState(null);
    const [rowClicked, setRowClicked] = useState<any>();
    const [archivedConfirm, setArchivedConfirm] = useState(false);
    const [unArchivedConfirm, setUnArchivedConfirm] = useState(false);
    const [showArchived, setShowArchived] = useState(false)
    const [generatorsData, setGeneratorsData] = useState([])
    const [generatorsDataTable, setGeneratorsDataTable] = useState([])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event, row) => {
        setRowClicked(row);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setRowClicked(null);
        setAnchorEl(null);
    };

    const tagStyle = (tag: string, type: string) => {
        let styles;
        let label;
        if ((tag === 'APPROVED') || (tag === 'PAID')) {
            styles = {//green palette
                color: '#22543D',
                backgroundColor: '#C6F6D5'
            }
            label = (tag === 'APPROVED' ? 'Aprobado' : 'Pagado');
        } else if ((tag === 'REJECTED') || (tag === 'DISABLED')) {
            styles = {//red palette
                color: '#822727',
                backgroundColor: '#FED7D7'
            }
            label = (tag === 'REJECTED' ? 'Rechazado' : 'Deshabilitado');
        } else if ((tag === 'REVISION') || (tag === 'DRAFT')) {
            styles = {//yellow palette
                color: '#7B341E',
                backgroundColor: '#FEEBCB'
            }
            label = (tag === 'REVISION' ? 'Bajo Revisión' : 'En proceso');
        } else if ((tag === 'STORED')) {
            styles = {//yellow palette
                color: '#4A4A4A',
                backgroundColor: '#F3F3F3'
            }
            label = 'Archivado'
        } else {
            styles = {//grey palette
                color: '#4A4A4A',
                backgroundColor: '#F3F3F3'
            }
            label = 'Pendiente de pago';
        }
        if (type === 'style') {
            return styles;
        } else {
            return label;
        }
    };

    const [columnFilters, setColumnFilters] = useState({
        generatedCode: '',
        externalId: '',
        providerName: '',
        rfc: '',
        total: '',
        startDate: '',
        projectName: '',
        city: '',
        cluster: '',
        status: '',
        statusPago: '',
    });

    const handleColumnFilterChange = (column, value) => {
        setColumnFilters({
            ...columnFilters,
            [column]: value
        });
    };

    const viewPDFOld = async (idPdfFile: string) => {
        setShowGenericLoader(true);
        try {
            await GeneradoresServices.getInstance().getImage(idPdfFile).then((data) => {
                const base64 = data.file;
                const binaryString = atob(base64);
                const len = binaryString.length;
                const bytes = new Uint8Array(len);
                for (let i = 0; i < len; i++) {
                    bytes[i] = binaryString.charCodeAt(i);
                }
                const blob = new Blob([bytes], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                setDataUrl(url);
                setPdfView(true);
                setShowGenericLoader(false);
            });
        } catch (error) {
            toast.error("Ha ocurrido un error al generar el PDF");
            setShowGenericLoader(false);
        }
    };

    const viewPDF = async (idPdfFile: string) => {
        setShowGenericLoader(true);
        try {
            await GeneradoresServices.getInstance().getPdf(idPdfFile).then(async (response) => {
                await GeneradoresServices.getInstance().getImage(response.respuesta).then((data) => {
                    const base64 = data.file;
                    const binaryString = atob(base64);
                    const len = binaryString.length;
                    const bytes = new Uint8Array(len);
                    for (let i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([bytes], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    setDataUrl(url);
                    setPdfView(true);
                    setShowGenericLoader(false);
                });
            });
        } catch (error) {
            toast.error("Ha ocurrido un error al generar el PDF");
            setShowGenericLoader(false);
        }
    };

    const archiveGenerator = async () => {
        setShowGenericLoader(true)
        await GeneradoresServices.getInstance().archiveGenerator(rowClicked.id).then(
            (response) => {
                if (response.codigo === 0) {
                    toast.success("Generador archivado con éxito")
                    getGeneratorsLite(mainCompanyId)
                    setShowGenericLoader(false)
                    setArchivedConfirm(false)
                } else {
                    toast.error("Ha ocurrido un error al archivar generador");
                    setShowGenericLoader(false);
                }
            }
        ).catch(error => {
            toast.error("Ha ocurrido un error al archivar generador");
            setShowGenericLoader(false);
        })
    }

    const unArchiveGenerator = async () => {
        setShowGenericLoader(true)
        await GeneradoresServices.getInstance().unArchiveGenerator(rowClicked.id).then(
            (response) => {
                if (response.codigo === 0) {
                    toast.success("Generador desarchivado con éxito")
                    getGeneratorsLite(mainCompanyId)
                    setShowGenericLoader(false)
                    setUnArchivedConfirm(false)
                } else {
                    toast.error("Ha ocurrido un error al desarchivar generador");
                    setShowGenericLoader(false);
                }
            }
        ).catch(error => {
            toast.error("Ha ocurrido un error al desarchivar generador");
            setShowGenericLoader(false);
        })
    }

    const getValidString = (value: string | undefined | null, defaultValue: string = ''): string => {
        return typeof value === 'string' ? value.toLowerCase() : defaultValue;
    };

    const handleFilterData = (dataRows) => {
        return dataRows.filter(item => {
            return (
                getValidString(item.generatedCode).includes(getValidString(searchTerm)) &&
                getValidString(item.generatedCode).includes(getValidString(columnFilters.generatedCode)) &&
                getValidString(item.externalId).includes(getValidString(columnFilters.externalId)) &&
                getValidString(item.providerName).includes(getValidString(columnFilters.providerName)) &&
                getValidString(item.rfc).includes(getValidString(columnFilters.rfc)) &&
                getValidString(item.total).includes(getValidString(columnFilters.total)) &&
                getValidString(item.startDate).includes(getValidString(columnFilters.startDate)) &&
                getValidString(item.projectName).includes(getValidString(columnFilters.projectName)) &&
                getValidString(item.city).includes(getValidString(columnFilters.city)) &&
                getValidString(item.cluster).includes(getValidString(columnFilters.cluster)) &&
                (
                    showArchived ?
                        getValidString(tagStyle(item.status, 'label')).includes(getValidString(columnFilters.status))
                        :
                        item.status !== 'STORED' &&
                        getValidString(tagStyle(item.status, 'label')).includes(getValidString(columnFilters.status))
                ) &&
                getValidString(item.statusPago).includes(getValidString(columnFilters.statusPago))
            );
        });
    }


    /* const filteredData = data.filter(item => {
        return (
            getValidString(item.generatedCode).includes(getValidString(searchTerm)) &&
            getValidString(item.generatedCode).includes(getValidString(columnFilters.generatedCode)) &&
            getValidString(item.externalId).includes(getValidString(columnFilters.externalId)) &&
            getValidString(item.providerName).includes(getValidString(columnFilters.providerName)) &&
            getValidString(item.rfc).includes(getValidString(columnFilters.rfc)) &&
            getValidString(item.total).includes(getValidString(columnFilters.total)) &&
            getValidString(item.startDate).includes(getValidString(columnFilters.startDate)) &&
            getValidString(item.projectName).includes(getValidString(columnFilters.projectName)) &&
            getValidString(item.city).includes(getValidString(columnFilters.city)) &&
            getValidString(item.cluster).includes(getValidString(columnFilters.cluster)) &&
            (
                showArchived ?
                    getValidString(tagStyle(item.status, 'label')).includes(getValidString(columnFilters.status))
                    :
                    item.status !== 'STORED' &&
                    getValidString(tagStyle(item.status, 'label')).includes(getValidString(columnFilters.status))
            ) &&
            getValidString(item.statusPago).includes(getValidString(columnFilters.statusPago))
        );
    }); */


    const emptyRows = rowsPerPage - Math.min(rowsPerPage, generatorsData.length - page * rowsPerPage);

    const getStatusClass = (status, classes) => {
        switch (status) {
            case 'PAGADO':
                return classes.pagado;
            case 'REVISION':
                return classes.revision;
            case 'RECHAZADO':
                return classes.rechazado;
            case 'PENDING':
                return classes.revision;
            default:
                return '';
        }
    };

    useEffect(() => {
        const filteredData = handleFilterData(generatorsLite)
        const filteredDataRows = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        setGeneratorsData(filteredData)
        setGeneratorsDataTable(filteredDataRows)
    }, [generatorsLite, page, showArchived, rowsPerPage, columnFilters, searchTerm]);

    return (
        <>
            {pdfView ? (
                <PdfModal open={pdfView} handleClose={() => { setPdfView(false); }} pdfBlobUrl={dataUrl}></PdfModal>
            ) : (
                <div className={classes.container}>
                    <div className={classes.searchContainer}>
                        {
                            generatorsLite.some(e => e.status === 'STORED') && <FormControlLabel control={<Checkbox value={showArchived} onChange={() => setShowArchived((prev) => !prev)} />} label="Mostrar archivados" />
                        }

                        <TextField
                            className={classes.searchInput}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder='Buscar'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <ReportGenerator filteredData={generatorsData} />
                    </div>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="custom table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="generatedCode">ID GENERADOR</label>
                                            <TextField
                                                fullWidth
                                                id="generatedCode"
                                                value={columnFilters.generatedCode}
                                                onChange={(e) => handleColumnFilterChange('generatedCode', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="externalId">ID PROVEEDOR</label>
                                            <TextField
                                                fullWidth
                                                id="externalId"
                                                value={columnFilters.externalId}
                                                onChange={(e) => handleColumnFilterChange('externalId', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="providerName">PROVEEDOR</label>
                                            <TextField
                                                fullWidth
                                                id="providerName"
                                                value={columnFilters.providerName}
                                                onChange={(e) => handleColumnFilterChange('providerName', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="rfc">RFC</label>
                                            <TextField
                                                fullWidth
                                                id="rfc"
                                                value={columnFilters.rfc}
                                                onChange={(e) => handleColumnFilterChange('rfc', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="startDate">FECHA INICIO</label>
                                            <TextField
                                                fullWidth
                                                id="startDate"
                                                value={columnFilters.startDate}
                                                onChange={(e) => handleColumnFilterChange('startDate', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="projectName">PROYECTO</label>
                                            <TextField
                                                fullWidth
                                                id="projectName"
                                                value={columnFilters.projectName}
                                                onChange={(e) => handleColumnFilterChange('projectName', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="city">CIUDAD</label>
                                            <TextField
                                                fullWidth
                                                id="city"
                                                value={columnFilters.city}
                                                onChange={(e) => handleColumnFilterChange('city', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="cluster">SERVICIO</label>
                                            <TextField
                                                fullWidth
                                                id="cluster"
                                                value={columnFilters.cluster}
                                                onChange={(e) => handleColumnFilterChange('cluster', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="total">MONTO</label>
                                            <TextField
                                                fullWidth
                                                id="total"
                                                value={columnFilters.total}
                                                onChange={(e) => handleColumnFilterChange('total', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="status">ESTATUS</label>
                                            <TextField
                                                fullWidth
                                                id="status"
                                                value={columnFilters.status}
                                                onChange={(e) => handleColumnFilterChange('status', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className={classes.labelContainer}>
                                            <label className={classes.labelCol} htmlFor="statusPago">ESTATUS PAGO</label>
                                            <TextField
                                                fullWidth
                                                id="statusPago"
                                                value={columnFilters.statusPago}
                                                onChange={(e) => handleColumnFilterChange('statusPago', e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <FilterList />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </TableCell>


                                </TableRow>
                            </TableHead>


                            <TableBody>
                                {generatorsDataTable.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.generatedCode}</TableCell>
                                        <TableCell>{row.externalId}</TableCell>
                                        <TableCell>{row.providerName}</TableCell>
                                        <TableCell>{row.rfc}</TableCell>
                                        <TableCell>{row.startDate}</TableCell>
                                        <TableCell>{row.projectName}</TableCell>
                                        <TableCell>{row.city}</TableCell>
                                        <TableCell>{row.cluster}</TableCell>
                                        <TableCell>{row.total}</TableCell>
                                        <TableCell className={classes.badgeContainer}>
                                            <Chip
                                                label={tagStyle(row.status, 'label')}
                                                style={tagStyle(row.status, 'style')}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.badgeContainer}>
                                            <Chip
                                                label={tagStyle(row.statusPago, 'label')}
                                                style={tagStyle(row.statusPago, 'style')} />
                                        </TableCell>
                                        <TableCell>
                                            <MenuIcon
                                                color="primary"
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                    handleClick(e, row);
                                                }}
                                            />
                                        </TableCell>
                                        <Popper id={id} open={open} anchorEl={anchorEl} placement="left-start" className={classes.popper}>
                                            <ClickAwayListener onClickAway={handleClickAway}>
                                                <Box className={classes.popperContent}>
                                                    <Box
                                                        onClick={async () => {
                                                            if (rowClicked?.pdfFileId !== null) {
                                                                console.log("GENERADOR PDF FILE" + row);
                                                                await viewPDF(rowClicked.id);
                                                            }
                                                        }}
                                                    >
                                                        <Chip
                                                            disabled={rowClicked?.pdfFileId !== null ? false : true}
                                                            clickable={true}
                                                            label={"Ver Generador"}
                                                            style={{ cursor: 'pointer', color: '#22543D', backgroundColor: '#C6F6D5', margin: '3px', width: '100%' }}
                                                        />
                                                    </Box>
                                                    {
                                                        rowClicked?.status !== "STORED" &&
                                                        <Box
                                                            onClick={() => {
                                                                if (rowClicked?.status !== "STORED") {
                                                                    /* setIdForm(rowClicked.id);
                                                                    viewForm(rowClicked.id); */
                                                                    setArchivedConfirm(true)
                                                                    setAnchorEl(null);
                                                                }
                                                            }}
                                                        >
                                                            <Chip
                                                                disabled={rowClicked?.status !== "STORED" ? false : true}
                                                                clickable={true}
                                                                label={"Archivar"}
                                                                style={{ cursor: 'pointer', color: '#5a5a5a', backgroundColor: '#d6d6d6', margin: '3px', width: '100%' }}
                                                            />
                                                        </Box>
                                                    }
                                                    {
                                                        rowClicked?.status === "STORED" &&
                                                        <Box
                                                            onClick={() => {
                                                                if (rowClicked?.status === "STORED") {
                                                                    /* setIdForm(rowClicked.id);
                                                                    viewForm(rowClicked.id); */
                                                                    setUnArchivedConfirm(true)
                                                                    setAnchorEl(null);
                                                                }
                                                            }}
                                                        >
                                                            <Chip
                                                                disabled={rowClicked?.status === "STORED" ? false : true}
                                                                clickable={true}
                                                                label={"Desarchivar"}
                                                                style={{ cursor: 'pointer', color: '#5a5a5a', backgroundColor: '#d6d6d6', margin: '3px', width: '100%' }}
                                                            />
                                                        </Box>
                                                    }
                                                </Box>
                                            </ClickAwayListener>
                                        </Popper>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={5} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        labelRowsPerPage={'rows'}
                        count={generatorsData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={() => (
                            <div className={classes.paginatorButtons}>
                                <IconButton onClick={() => handleChangePage(null, 0)} disabled={page === 0} aria-label="first page">
                                    <FirstPage />
                                </IconButton>
                                <IconButton onClick={() => handleChangePage(null, page - 1)} disabled={page === 0} aria-label="previous page">
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <IconButton onClick={() => handleChangePage(null, page + 1)} disabled={page >= Math.ceil(generatorsData.length / rowsPerPage) - 1} aria-label="next page">
                                    <KeyboardArrowRight />
                                </IconButton>
                                <IconButton onClick={() => handleChangePage(null, Math.max(0, Math.ceil(generatorsData.length / rowsPerPage) - 1))} disabled={page >= Math.ceil(generatorsData.length / rowsPerPage) - 1} aria-label="last page">
                                    <LastPage />
                                </IconButton>
                            </div>
                        )}
                    />
                    {
                        archivedConfirm && <ArchiveMessageModal open={archivedConfirm} handleClose={() => { setArchivedConfirm(false) }} handleArchive={archiveGenerator} />
                    }
                    {
                        unArchivedConfirm && <UnArchiveMessageModal open={unArchivedConfirm} handleClose={() => { setUnArchivedConfirm(false) }} handleUnArchive={unArchiveGenerator} />
                    }
                </div >
            )}
        </>
    );
};

export default CustomBiddingTable;
